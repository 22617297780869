<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-text-field :rules="[(val) => !!val || 'Введите название']" outlined :label="destination ? 'Куда' : 'Откуда'"
                v-model="internalValue.place" />
        </v-col>

        <v-col cols="6" class="pr-2">
            <v-text-field outlined :label="destination ? 'Дата прилета' : 'Дата вылета'" :value="normalizedDate"
                @input="(val) => dateStr = updateStr(val, dateStr, '.')" maxlength="10" hint="ДД:ММ:ГГГГ"
                :rules="[checkDate || 'Введите дату в формате ДД:ММ:ГГГГ']" persistent-hint />
        </v-col>
        <v-col cols="6" class="pl-2">
            <v-text-field outlined :label="destination ? 'Время прилета' : 'Время вылета'" :value="normalizedTime"
                @input="(val) => timeStr = updateStr(val, timeStr, ':')" maxlength="5" hint="ЧЧ:ММ"
                :rules="[checkTime || 'Введите время в формате ЧЧ:ММ']" persistent-hint />
        </v-col>
    </v-row>
</template>

<script>

import CustomDatePicker from "@/components/UI/CustomDatePicker.vue";
export default {
    name: "SegmentPlaceForm",
    components: { CustomDatePicker },
    model: {
        prop: "value",
        event: "update:value",
    },
    props: {
        value: {
            type: Object | undefined,
            required: true,
        },
        destination: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            internalValue: {},
            timeStr: '',
            dateStr: ''
        };
    },
    async mounted() {
        await this.$nextTick();
        this.internalValue = this.value;
    },
    watch: {
        internalValue(val) {
            this.$emit("update:value", val);
        },
        value(val) {
            console.log('value', val)
            this.internalValue = val
            if (!!val.date) {
                const parsed = this.parseDate(val.date);
                console.log(parsed)
                if (this.timeStr != parsed.time) this.timeStr = parsed.time
                if (this.dateStr != parsed.date) this.dateStr = parsed.date
            }
            else {
                this.dateStr = '';
                this.timeStr = '';
            }
        },
        timeStr(val) {
            if (!this.timeIsValid || !this.dateIsValid) return;
            if (!this.internalValue)
                this.internalValue = {}

            const newDate = this.calculateDate(this.dateStr, val);

            if (!!this.internalValue.date && this.internalValue.date == newDate) return;

            this.internalValue.date = newDate
        },
        dateStr(val) {
            if (!this.timeIsValid || !this.dateIsValid) return;
            if (!this.internalValue)
                this.internalValue = {}

            const newDate = this.calculateDate(val, this.timeStr);

            if (!!this.internalValue.date && this.internalValue.date == newDate) return;

            this.internalValue.date = newDate
        }
    },
    methods: {
        updateStr(value, target, subStr) {
            return value.replaceAll(subStr, "")
        },
        parseDate(date) {
            if (!date) return { date: '', time: '' };
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const datePart = '' + (day < 10 ? '0' + day : day) + (month < 10 ? '0' + month : month) + date.getFullYear();

            const hours = date.getHours();
            const minutes = date.getMinutes();
            const timePart = '' + (hours < 10 ? '0' + hours : hours) + (minutes < 10 ? '0' + minutes : minutes);
            return { date: datePart, time: timePart }
        },
        calculateDate(date, time) {
            const day = Number(date.substring(0, 2));
            const mounth = Number(date.substring(2, 4)) - 1;
            const year = Number(date.substring(4, 8));
            const hours = Number(time.substring(0, 2));
            const minutes = Number(time.substring(2, 4));
            return new Date(year, mounth, day, hours, minutes)
        }
    },
    computed: {
        normalizedTime() {
            let str = this.timeStr.substring(0, 2);
            if (this.timeStr.length > 2)
                str += ":" + this.timeStr.substring(2, 4)
            return str;
        },
        checkTime() {
            if (!this.timeStr) return false;
            let timeFirstDidgits = Number(this.timeStr.substring(0, 2));
            let timeSecondDidgits = Number(this.timeStr.substring(2, 4));
            return ((timeFirstDidgits >= 0 && timeFirstDidgits < 24) && (timeSecondDidgits >= 0 && timeSecondDidgits < 60))
        },
        timeIsValid() {
            return !!this.checkTime && this.normalizedTime.length == 5
        },

        normalizedDate() {
            let str = this.dateStr.substring(0, 2);
            if (this.dateStr.length > 2)
                str += "." + this.dateStr.substring(2, 4)
            if (this.dateStr.length > 4)
                str += "." + this.dateStr.substring(4, 8)
            return str;
        },
        checkDate() {
            if (!this.dateStr) return false;
            let day = Number(this.dateStr.substring(0, 2));
            let mounth = Number(this.dateStr.substring(2, 4));
            let year = Number(this.dateStr.substring(4, 8));
            return (day > 0 && day < 32) && (mounth > 0 && mounth < 13) && (year > 2023)
        },
        dateIsValid() {
            return this.checkDate && this.normalizedDate.length == 10
        },
    }
};
</script>

<style scoped></style>