<template>
    <v-card-title>
        <v-row justify="space-between">
            <v-col cols="12" md="10" class="text-h4">
                <slot></slot>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn large dark :color="isActive ? 'orange' : 'green'" width="100%" @click="toggleScreenState">
                    {{ isActive ? 'Выключить' : 'Включить' }}
                </v-btn>
            </v-col>
        </v-row>
    </v-card-title>
</template>

<script>

export default {
    name: "PanelHeader",
    data() {
        return {
            showFor: new Date(),
            isActive: false
        }
    },
    methods: {
        async toggleScreenState() {
            let resp;
            if (this.isActive)
                resp = await this.$axios.post(this.$enums.Endpoints.Other.Screen.Disable);
            else
                resp = await this.$axios.post(this.$enums.Endpoints.Other.Screen.Enable);
            if (!resp || !resp.data)
                return;
            this.updateActive(resp.data)
        },
        updateActive(data) {
            this.showFor = new Date(data.activeTo)
            // Считать неактивным, если осталось меньше 10сек
            this.isActive = (this.showFor - new Date()) > (10 * 1000)
        }
    },
    async mounted() {
        let resp = await this.$axios.get(this.$enums.Endpoints.Other.Screen.Get);
        if (!resp || !resp.data || this.type)
            return;
        this.updateActive(resp.data)
    },
};
</script>

<style></style>