<template>
    <v-card flat outlined class="d-flex justify-center align-center card-btn" @click="click">
        <v-icon color="orange" size="150">mdi-plus</v-icon>
    </v-card>
</template>

<script>

export default {
    name: "TaxAddBtn",
    methods: {
        click() {
            this.$emit('click');
        },
    },
};
</script>

<style>
.card-btn {
    min-height: 100%;
    border: solid 2px orange !important;
}

.card-btn:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2) !important;
}
</style>